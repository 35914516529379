<template>
  <VCard class="grey lighten-5">
    <VAlert
      v-if="!valid"
      class="alert"
      outlined
      type="error"
    >
      Заполните все обязательные поля
    </VAlert>
    <DeletionPopUp
      v-if="showDeletionPopup"
      :title="deletionTitle"
      @confirm="deletionEvents[`${currentDeletionEvent}Close`]($event === 'delete')"
    />
    <VCol
      class="d-flex flex-wrap main-block"
      cols="12"
    >
      <VCol
        class="d-flex align-center"
        cols="12"
      >
        <span class="text-h4">
          {{ contractId ? 'Редактирование контракта' : 'Создание контракта' }}
        </span>
        <VCheckbox
          v-if="contractId"
          class="ml-4"
          color="success"
          :disabled="!contract.canApprove"
          :ripple="false"
          :value="contract.approved"
          @change="changeApprovementStatus"
        >
          <template v-slot:label>
            <span :class="contract.approved ? 'text--green' : 'red--text'">
              {{ contract.approved ? 'Проверено' : 'Не проверено' }}
            </span>
          </template>
        </VCheckbox>
        <div
          v-if="defaultContract.terminatedAt"
          class="ml-4 my-auto d-flex align-center cancel-text"
        >
          <VIcon
            class="mr-1"
            color="#C84620"
            size="19"
            v-text="'mdi-block-helper'"
          />
          <span>
            {{
               contract.isTerminated
                 ? `Контракт расторгнут от ${contract.terminatedAt}`
                 : `От ${contract.terminatedAt} данный контракт будет расторгнут`
            }}
          </span>
        </div>
      </VCol>
      <VCol class="pa-0" cols="5">
        <VForm
          ref="form"
          v-model="valid"
          class="white br-8 pb-9"
          lazy-validation
        >
          <ContractsPartnerInfo
            ref="partner"
            :input-width="inputWidth"
            :input-wrapper-width="inputWidth"
            :managers="managers"
            :partner-contract="contract"
            @search-manager="searchManager"
          />
          <VCol
            v-if="!contract.isTerminated"
            class="d-flex justify-end pt-6"
            cols="12"
          >
            <VBtn
              v-if="contractId && contract.canDelete"
              class="br-8 px-3"
              color="#E43539"
              height="40"
              outlined
              @click="removeContract"
            >
              <span class="black--text subtitle-1">Удалить</span>
            </VBtn>
            <VBtn
              v-if="contractId"
              class="br-8 px-3 ml-3"
              color="#E43539"
              height="40"
              outlined
              @click="termination.showCreate = true"
            >
              <span class="black--text subtitle-1">
                {{ defaultContract.terminatedAt ? 'Изменить расторжение' : 'Расторгнуть' }}
              </span>
            </VBtn>
            <VBtn
              class="elevation-0 br-8 ml-3 mr-2 px-3"
              color="#E43539"
              height="40"
              @click="saveContract"
            >
              <VIcon
                color="white"
                size="20"
                v-text="contractId ? 'mdi-check' : 'mdi-plus'"
              />
              <span class="white--text ml-1 subtitle-1">
                {{ contractId ? 'Cохранить' : 'Создать' }}
              </span>
            </VBtn>
          </VCol>
        </VForm>
      </VCol>
      <Transition name="addition">
        <VCol
          v-if="contractId"
          class="pl-6 py-0 additional-block"
          cols="7"
        >
          <VCol
            :key="contactsKey"
            class="white br-4 pb-6 px-6 contacts-block"
            cols="12"
          >
            <div class="text-h5 font-weight-bold mt-3 mb-8">Контакты</div>
            <VRow class="mb-2">
              <VCol
                v-for="header in contractContactRows"
                :key="header.text"
                class="font-weight-bold grey--text text--darken-1"
                :class="header.classList"
              >
                {{ header.text }}
              </VCol>
            </VRow>
            <VDivider/>
            <ContractsContact
              creating
              :roles="contract.contactRoles"
              @contact-event="addContact"
            />
            <ContractsContact
              v-for="(contact, index) in contract.contacts"
              :key="contact.phone"
              button-text="Обновить"
              :contact="contact"
              :roles="contract.contactRoles"
              @contact-event="updateContact($event, index)"
              @delete="deletionEvents.contact"
            />
          </VCol>
          <VCol
            class="mt-6 white br-4 pa-6"
            cols="12"
          >
            <ContractPrepayments
              :contract-id="contractId"
              :prepayments-details="contract.prepaymentsDetails"
            />
          </VCol>
          <VCol
            class="mt-6 white br-4 pa-6"
            cols="12"
          >
            <div class="text-h5 font-weight-bold mb-5">Комиссия ресторана(-ов)</div>
            <UniversalTable
              v-if="contract.restaurantsCommissions.length"
              class="restaurants-commissions-table"
              :head-rows="contractRestaurantsCommissions"
            >
              <template v-slot:tr>
                <ContractCommission
                  v-for="commission in contract.restaurantsCommissions"
                  :key="commission.id"
                  :commission="commission"
                  @delete="showDeleteCommission(commission)"
                  @edit="openCommissionDialog(commission)"
                />
              </template>
            </UniversalTable>
            <ConfirmationDialog
              v-model="commission.showDelete"
              btn="Да, удалить"
              :text="
                `Будет удалена ${commission.toEdit.percent}% комиссия
                для ${commission.toEdit.restaurantTitle} от ${commission.toEdit.dateStart}`
              "
              @confirm="deleteCommission"
            />
            <div class="full-width d-flex justify-end">
              <VBtn
                class="elevation-0 py-5 br-8 mt-4 pl-auto"
                color="#E43539"
                @click="openCommissionDialog"
              >
                <VIcon color="white" v-text="'mdi-plus'"/>
                <span class="subtitle-1 white--text mr-1">
                  Добавить комиссию
                </span>
              </VBtn>
              <VDialog
                v-model="commission.showEdit"
                content-class="br-8 overflow-hidden"
                max-width="720"
              >
                <ContractCommissionEdit
                  :key="contractCommissionKey"
                  :value="commission.toEdit"
                  @close="commission.showEdit = false"
                  @update="getContractData"
                />
              </VDialog>
            </div>
          </VCol>
          <ContractEventsLog/>
        </VCol>
      </Transition>
    </VCol>
    <VDialog
      v-model="termination.showCreate"
      max-width="500"
    >
      <VCard class="br-8">
        <div class="base-red pl-4 pr-3 py-3 d-flex align-center justify-space-between">
          <span class="white--text subtitle-1 font-weight-bold">Расторжение контракта</span>
          <VBtn
            icon
            small
            @click="termination.showCreate = false"
          >
            <VIcon
              color="white"
              size="22"
              v-text="'mdi-close'"
            />
          </VBtn>
        </div>
        <div class="pa-4">
          <div class="body-2 mb-3">
            <span v-if="defaultContract.terminatedAt">
              От {{ contract.terminatedAt }} данный контракт будет расторгнут. <br>
              Вы можете изменить дату расторжения.
            </span>
            <span v-else>
              Выберите дату расторжения контракта
            </span>
          </div>
          <DatePickerTextfield
            :date="contract.terminatedAt"
            immediate
            label="Дата расторжения"
            :min="new Date().toISOString()"
            :outlined="false"
            @date-selected="contract.terminatedAt = $event"
          />
        </div>
        <div v-if="!defaultContract.terminatedAt" class="pa-4 d-flex justify-end">
          <VBtn
            class="br-8"
            color="#E43539"
            outlined
            @click="termination.showCreate = false"
          >
            <span class="black--text body-2">
              Отмена
            </span>
          </VBtn>
          <VBtn
            class="elevation-0 br-8 ml-2"
            color="#E43539"
            :disabled="!contract.terminatedAt"
            @click="termination.showConfirm = true"
          >
            <span class="white--text body-2">Расторгнуть контракт</span>
          </VBtn>
        </div>
        <div v-if="defaultContract.terminatedAt" class="pa-4 d-flex justify-end">
          <VBtn
            class="br-8"
            color="#E43539"
            outlined
            @click="termination.showCancel = true"
          >
            <span class="black--text body-2">
              Отменить расторжение
            </span>
          </VBtn>
          <VBtn
            class="elevation-0 br-8 ml-2"
            color="#E43539"
            :disabled="contract.terminatedAt === defaultContract.terminatedAt"
            @click="updateContractTerminationDate(contract.terminatedAt)"
          >
            <span class="white--text body-2">Изменить дату</span>
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <ConfirmationDialog
      v-model="termination.showConfirm"
      btn="Подтвердить расторжение"
      :text="`Контракт будет расторгнут от ${contract.terminatedAt}`"
      @cancel="termination.showCreate = false"
      @confirm="updateContractTerminationDate(contract.terminatedAt)"
    />
    <ConfirmationDialog
      v-model="termination.showCancel"
      btn="Да, отменить расторжение"
      :text="`Отменить расторжение контракта от ${defaultContract.terminatedAt}`"
      @cancel="termination.showCancel = false"
      @confirm="updateContractTerminationDate()"
    />
  </VCard>
</template>

<script>
import ExtendedContract from '@/serializers/ContractExtendedSerializer';
import ContractsPartnerInfo from '@/components/ContractsPartnerInfo.vue';
import ContractsContact from '@/components/ContractsContact.vue';
import ContractCommission from '@/components/ContractCommission.vue';
import ContractEventsLog from '@/components/ContractEventsLog.vue';
import DeletionPopUp from '@/components/DeletionPopUp.vue';
import UniversalTable from '@/components/UniversalTable.vue';
import ContractCommissionEdit from '@/components/ContractCommissionEdit.vue';
import {
  createContract,
  updateContract,
  deleteContract,
  fetchContract,
  addContractsContact,
  updateContractsContact,
  deleteContractsContact,
  getUsers,
  updateContractCommission,
} from '@/api/api';

import {
  contractContactRows,
  contractRestaurantsCommissions,
} from '@/conditions/tablesHeaders';
import DatePickerTextfield from '@/components/DatePickerTextfield.vue';
import ConfirmationDialog from '@/components/reusable/ConfirmationDialog.vue';
import ContractPrepayments from '@/components/collection/ContractPrepayments.vue';

const contract = () => JSON.parse(JSON.stringify(new ExtendedContract()));

export default {
  name: 'contractEdit',
  components: {
    ContractPrepayments,
    ConfirmationDialog,
    DatePickerTextfield,
    ContractCommissionEdit,
    ContractsPartnerInfo,
    ContractsContact,
    DeletionPopUp,
    UniversalTable,
    ContractCommission,
    ContractEventsLog,
  },
  data() {
    return {
      contract: contract(),
      defaultContract: contract(),
      inputWidth: 12,
      inputWrapperWidth: 10,
      showDeletionPopup: false,
      deletionTitle: '',
      contractContactRows,
      contractRestaurantsCommissions,
      contractId: null,
      deletionEvents: {
        contact: (contact) => this.prepareContactDeletion(contact),
        contactClose: (acception) => this.removeContact(acception),
      },
      currentDeletionEvent: '',
      managers: [],
      disableCommissionsButtons: false,
      contractCommissionKey: 0,
      contactsKey: 0,
      valid: true,
      commission: {
        showEdit: false,
        showDelete: false,
        toEdit: {},
      },
      termination: {
        showCreate: false,
        showConfirm: false,
        showCancel: false,
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.contractId = this.$route.params.id;
      this.getContractData();
    }
  },
  methods: {
    getContractData() {
      fetchContract(this.contractId).then((result) => {
        this.contract = result;
        this.defaultContract = JSON.parse(JSON.stringify(result));
      });
    },
    changeApprovementStatus(isApproved) {
      const contractInfo = this.$refs.partner.$data.contract.deserialized;
      contractInfo.approved = Boolean(isApproved);
      contractInfo.terminated_at = contractInfo.terminated_at || null;
      updateContract(contractInfo)
        .then(() => {
          this.contract.approved = Boolean(isApproved);
        })
        .catch(() => {
          this.contract.approved = !isApproved;
        });
    },
    openCommissionDialog(commission = {}) {
      this.commission.toEdit = commission;
      this.contractCommissionKey += 1;
      this.commission.showEdit = true;
    },
    saveContract() {
      const contractInfo = this.$refs.partner.$data.contract;
      if (this.$refs.form.validate()) {
        const contractToSend = new ExtendedContract();
        const parsedContract = JSON.parse(JSON.stringify(contractInfo));
        Object.assign(
          contractToSend,
          parsedContract,
        );
        if (!this.contractId) {
          createContract(contractToSend.deserialized)
            .then((newContract) => {
              this.contract = newContract;
              this.contractId = newContract.id;
              this.$router.push({ name: 'contracts-edit', params: { id: this.contractId } });
            });
        } else {
          updateContract(contractToSend.deserialized);
        }
      }
    },
    removeContract() {
      deleteContract(this.contractId).then(() => {
        this.$router.push({
          name: 'contracts',
        });
      });
    },
    addContact(newContact) {
      addContractsContact(this.contractId, newContact).then((contact) => {
        this.contract.contacts.push(contact);
      });
    },
    updateContact(updatedContact, index) {
      updateContractsContact(this.contractId, updatedContact).then(() => {
        this.contract.contacts[index] = updatedContact;
        this.contactsKey += 1;
      });
    },
    prepareContactDeletion(contact) {
      this.currentDeletionEvent = 'contact';
      this.showDeletionPopup = true;
      this.deletionTitle = contact.name;
      this.itemToDelete = contact;
    },
    removeContact(acception) {
      this.showDeletionPopup = false;
      const temporaryItem = this.itemToDelete;
      if (acception) {
        deleteContractsContact(this.contractId, temporaryItem.id).then(() => {
          this.contract.contacts = this.contract.contacts
            .filter(
              (element) => element.id !== temporaryItem.id,
            );
          this.itemToDelete = null;
        });
      }
    },
    searchManager(managerEmail) {
      getUsers(managerEmail).then((result) => {
        this.managers = result.emails;
      });
    },
    showDeleteCommission(commission) {
      this.commission.toEdit = commission;
      this.commission.showDelete = true;
    },
    deleteCommission() {
      this.commission.toEdit.isDeleted = true;
      updateContractCommission(this.commission.toEdit).then(() => {
        this.getContractData();
      });
      this.commission.showDelete = false;
    },
    updateContractTerminationDate(date = null) {
      this.termination.showCreate = false;
      const contractToTerminate = new ExtendedContract();
      const contractToAssign = JSON.parse(JSON.stringify(this.defaultContract));
      contractToAssign.terminatedAt = date;
      Object.assign(
        contractToTerminate,
        contractToAssign,
      );
      updateContract(contractToTerminate.deserialized).then(() => {
        this.getContractData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .titles-thead th,
  .restaurants-commissions-table tbody tr:not(:last-child) td,
  .event-log-table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #E6E0EC !important;
  }
  .restaurants-commissions-table tbody tr:hover {
    background-color: #E5FFEA !important;
  }
  .contacts-block {
    .job-title {
      min-width: 230px;
    }
    .action {
      min-width: 110px;
      max-width: 110px;
      margin: auto 0;
    }
    .col {
      padding: 3px 8px;
    }
  }
}

.alert {
  position: fixed;
  right: 10px;
  bottom: 0;
}

.addition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.addition-enter-active,
.addition-leave-active {
  transition: transform .5s, opacity .4s;
}

.cancel-text {
  color: #C84620;
}
</style>
